// Torch.js

import { $ } from './Utils.js';

/**
 * Class representing a torchlight effect that follows the mouse cursor.
 */
export default class Torch {
  /**
   * Creates an instance of Torch.
   * @param {boolean} [autoStart=false] - Whether to start the torch effect immediately.
   */
  constructor(autoStart = true) {
    // Initial positions
    this.position = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    this.mousePos = { ...this.position };
    this.lightRadius = .85;

    // State variables
    this.menuIsActive = false;
    this.transitionProgress = 0;
    this.lastTime = performance.now();

    // Bind methods
    this.updateSpotlight = this.updateSpotlight.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onResize = this.onResize.bind(this);
    this.handleMenuToggle = this.handleMenuToggle.bind(this);
    this.removeSpotlight = this.removeSpotlight.bind(this);

    // MutationObserver
    this.observer = null;

    if (autoStart) {
      this.start();
    }
  }

  /**
   * Starts the torch effect.
   */
  start() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('beforeunload', this.removeSpotlight);

    const menuElement = $('#slide-menu');
    if (menuElement) {
      this.observer = new MutationObserver(this.handleMenuToggle);
      this.observer.observe(menuElement, {
        attributes: true,
        attributeFilter: ['class'],
      });
    }

    this.animationId = requestAnimationFrame(this.updateSpotlight);
  }

  /**
   * Handles menu toggle events to update the torch effect accordingly.
   * @param {MutationRecord[]} mutations - List of mutations.
   */
  handleMenuToggle(mutations) {
    mutations.forEach((mutation) => {
      if (mutation.attributeName === 'class') {
        const menuElement = mutation.target;
        this.menuIsActive = menuElement.classList.contains('is-active');
        this.transitionProgress = 0;
      }
    });
  }

  /**
   * Linearly interpolates between two values.
   * @param {number} start - The start value.
   * @param {number} end - The end value.
   * @param {number} t - The interpolation factor (0 to 1).
   * @returns {number} The interpolated value.
   */
  lerp(start, end, t) {
    return start * (1 - t) + end * t;
  }

  /**
   * Updates the spotlight position and handles transitions.
   * @param {DOMHighResTimeStamp} timestamp - The current time.
   */
  updateSpotlight(timestamp) {
    const deltaTime = timestamp - this.lastTime;
    this.lastTime = timestamp;

    // Update transition progress
    this.transitionProgress = Math.min(
      this.transitionProgress + deltaTime * 0.002,
      1,
    );

    // Determine target position
    const targetPos = this.menuIsActive
      ? { x: window.innerWidth, y: window.innerHeight / 2 }
      : this.mousePos;

    // Lerp towards the target position
    this.position.x = this.lerp(
      this.position.x,
      targetPos.x,
      this.transitionProgress,
    );
    this.position.y = this.lerp(
      this.position.y,
      targetPos.y,
      this.transitionProgress,
    );

    // Update CSS variables
    document.documentElement.style.setProperty('--x', `${this.position.x}px`);
    document.documentElement.style.setProperty('--y', `${this.position.y}px`);
    document.documentElement.style.setProperty(
      '--light-radius',
      `${this.lightRadius}`,
    );

    // Continue the animation loop
    this.animationId = requestAnimationFrame(this.updateSpotlight);
  }

  /**
   * Handles mouse move events to update the mouse position.
   * @param {MouseEvent} e - The mouse event.
   */
  onMouseMove(e) {
    this.mousePos.x = e.clientX;
    this.mousePos.y = e.clientY;
    this.transitionProgress = 0;
  }

  /**
   * Handles window resize events to adjust positions.
   */
  onResize() {
    this.position = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    this.mousePos = { ...this.position };
    this.transitionProgress = 0;
  }

  /**
   * Stops the torch effect and removes event listeners.
   */
  removeSpotlight() {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('beforeunload', this.removeSpotlight);

    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }

    if (this.animationId) {
      cancelAnimationFrame(this.animationId);
      this.animationId = null;
    }
  }
}
