// Clock.js

import { $ } from './Utils.js';

/**
 * Class representing a digital clock that displays the current time.
 */
export default class Clock {
  /**
   * Creates an instance of Clock.
   * @param {string} selector - The CSS selector of the element where the time will be displayed.
   * @param {boolean} [autoStart=true] - Whether to start the clock immediately.
   */
  constructor(selector, autoStart = true) {
    this.timeElement = $(selector);
    this.intervalId = null;

    if (!this.timeElement) {
      console.error(
        `Clock initialization failed: Element not found for selector "${selector}".`,
      );
      return;
    }

    if (autoStart) {
      this.start();
    }
  }

  /**
   * Updates the time display.
   */
  updateTime() {
    if (!this.timeElement) {
      return;
    }

    const now = new Date();
    this.timeElement.textContent = now.toLocaleTimeString('en-GB', {
      hour12: false,
    });
  }

  /**
   * Starts the clock.
   */
  start() {
    if (this.intervalId !== null) {
      // Clock is already running
      return;
    }

    this.updateTime();

    this.intervalId = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  /**
   * Stops the clock.
   */
  stop() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  /**
   * Toggles the clock between running and stopped states.
   */
  toggle() {
    if (this.intervalId === null) {
      this.start();
    } else {
      this.stop();
    }
  }

  /**
   * Destroys the clock instance and cleans up resources.
   */
  destroy() {
    this.stop();
    this.timeElement = null;
  }
}
