import Clock from "./Clock.js";
import Torch from "./Torch.js";
import { $ } from "./Utils.js";

document.addEventListener("DOMContentLoaded", () => {
  const clock = new Clock(".the-time");
  const torch = new Torch();
  $(".current-year").textContent = new Date().getFullYear().toString();

  $(".hamburger").addEventListener("click", () => {
    $(".hamburger").classList.toggle("is-active");
    $("#slide-menu").classList.toggle("is-active");
  });
});

async function navigateWithTransition(url) {
  if (document.querySelector("#slide-menu")) {
    $(".hamburger").classList.remove("is-active");
  }
  setTimeout(() => {
    if (document.startViewTransition) {
      document.startViewTransition(() => {
        window.location.href = url;
      });
    } else {
      window.location.href = url;
    }
  }, 1000);
}

document.body.addEventListener("click", async (event) => {
  const link = event.target.closest("a[data-link]");
  if (link) {
    event.preventDefault();
    const url = link.getAttribute("href");
    await navigateWithTransition(url);
  }
});

window.addEventListener("load", async (event) => {
  $(".fader").classList.add("faded");
  setTimeout(() => {
    $("#home-reel").classList.add("faded");
  }, 1000);
});
